import * as React from "react";
import { Link, graphql } from "gatsby";

import Layout from "../components/layout";
import Seo from "../components/seo";
import descriptionData from "../content/description.json";
import ProductContainer from "../components/productContainer";

const About = ({  }) => {
  return (
    <Layout>
      <Seo
        title="About Collective Bricks"
        description="Collective Bricks offers a curated selection of latest dress styles from leading designers and brands, providing a time-efficient shopping experience. Our comprehensive collection serves all your dress needs, making us the ultimate one-stop-shop for fashionable attire."
      />

      <div className="descriptions-container container px-18 max-w-screen-xl mx-auto">
        <h1 class="text-2xl mt-5 mb-5 font-bold">About Collective Bricks</h1>
        <p>
        Welcome to Collective Bricks, your one-stop destination for everything Lego. Our site is a warm and welcoming platform for Lego enthusiasts across the globe. Our main focus is to provide fans of all ages with a comprehensive, efficient, and enjoyable Lego experience.
        </p>

        <p>
        Our site offers a comprehensive selection of Lego products, catering to the varied interests and specific preferences of Lego builders, collectors and fans. From classic sets dating back decades to the latest releases from Lego Star Wars, Lego Technic, Lego City, Lego Marvel Super Heroes, and many more, our catalog caters to all hobbyists, irrespective of their age.
        </p>

        <p>
        Join us on this dynamic and remarkable journey, as we continue exploring and fostering the limitless realm of Lego creativity. 
        </p>
      </div>
    </Layout>
  );
};

export default About;
